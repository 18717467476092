import { type ZodTypeAny, ZodType } from 'zod'
import type { MaybeRefOrGetter } from 'vue'

export function zRequiredIf<T extends ZodTypeAny | Record<string, ZodTypeAny>>(
    type: T,
    isRequired: MaybeRefOrGetter<boolean>
    // @ts-ignore
    // TODO: fix type and maybe use T[K]['_def'] instead of '_input'
): T extends ZodTypeAny ? ZodType<T['_output'], T['_input']> : { [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input']> } {
    const isRequiredValue = toValue(isRequired)

    if (type?._def?.typeName) {
        // Handling when type is ZodTypeAny
        // @ts-ignore
        return (isRequiredValue ? type : type.optional()) as T extends ZodTypeAny ? ZodType<T['_output'], T['_input'], T['_output']> : never
    }

    // Handling when type is a record of string keys to ZodTypeAny values
    // @ts-ignore
    const result: Partial<{ [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input'], T[K]['_output']> }> = {}
    for (const key in type) {
        const value = type[key]
        // @ts-ignore
        if (!(value?._def?.typeName)) {
            continue // Or handle error
        }
        // @ts-ignore
        result[key] = isRequiredValue ? value : value.optional()
    }
    // @ts-ignore
    return result as T extends Record<string, ZodTypeAny> ? { [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input'], T[K]['_output']> } : never
}
